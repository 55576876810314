// @flow
import orderBy from 'lodash/orderBy';

import {getPageUrl} from './urlFormatters';

// $FlowFixMe -- We disable Flow in this file because it needs to be consumed by gatsby-node.esm.js, which does not support Flow. Should be resolved later on.
export const getPageList = (rawPages) => {
  const newArr = [];

  // eslint-disable-next-line no-unused-vars -- Necessary for generating our pages
  Object.entries(rawPages.edges).map(([key, value]) => {
    const page = value.node;
    const url = getPageUrl(page.parent.relativePath, page.frontmatter.slug);

    // We don't want to include the homepage in the page list, because it is not part of the ToC
    if (url === '/') {
      return null;
    }

    const remappedObject = {
      id: page.id,
      order: page.frontmatter.order,
      title: page.frontmatter.title ? page.frontmatter.title : page.parent.name,
      summary: page.frontmatter.summary,
      url: getPageUrl(page.parent.relativePath, page.frontmatter.slug),
    };

    newArr.push(remappedObject);
  });

  // Reorder the array before returning it
  return orderBy(newArr, ['order', 'title'], ['asc', 'asc']);
};

// $FlowFixMe -- We disable Flow in this file because it needs to be consumed by gatsby-node.esm.js, which does not support Flow. Should be resolved later on.
export const getPageNavigation = (allPages, currentPageUrl) => {
  // We only want to proceed if there are sufficient pages to query, and if the current page was matched
  let currentPageIndex;
  if (allPages.length > 1) {
    currentPageIndex = allPages.findIndex(
      (item) => item.url === currentPageUrl,
    );
  } else {
    return null;
  }

  // Add the chapter ID to the current page only
  let currentItem = allPages[currentPageIndex];
  currentItem = {...currentItem, ...{chapter: currentPageIndex + 1}};

  const totalPages = allPages.length;
  let nextItem = null;
  let previousItem = null;

  // There is only a "previous" page if we're not on the first page
  if (currentPageIndex > 0) {
    previousItem = allPages[currentPageIndex - 1];
  }

  // There is only a "next" page if we're not on the last page
  if (currentPageIndex < totalPages - 1) {
    nextItem = allPages[currentPageIndex + 1];
  }

  return {
    previous: previousItem,
    current: currentItem,
    next: nextItem,
  };
};
